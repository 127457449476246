<template>
  <v-container fluid fill-height class="d-flex justify-center align-center">
    <div class="d-flex flex-column justify-center align-center w-100 pa-5">
      <v-img src="../assets/svg/404.svg" class="mb-5" />
      <span
        class="white--text app-bold-font"
        :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'"
      >
        The page not found
      </span>
      <span
        class="mt-2 text-center white--text"
        :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
      >
        The page you are seeking is either not found or has access limitations.
      </span>
      <div class="mt-10">
        <v-btn color="#0a5b8a" @click="goHome" dark>
          Go home
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    }
  }
};
</script>
<style scoped></style>
